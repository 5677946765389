import { render, staticRenderFns } from "./SelectedTagsAutocomplete.vue?vue&type=template&id=426a3968&scoped=true"
import script from "./SelectedTagsAutocomplete.vue?vue&type=script&setup=true&lang=ts"
export * from "./SelectedTagsAutocomplete.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SelectedTagsAutocomplete.vue?vue&type=style&index=0&id=426a3968&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "426a3968",
  null
  
)

export default component.exports